
function trataMenu(lista) {

    var novaLista = [];

    lista.forEach(item => {

        // inclui a funcionalidade caso não exista
        if (item.codeMenu != null && item.codeMenu != 'null') {
            if (!novaLista.includes(item.codeMenu)) {
                novaLista.push(item.codeMenu);
            }
        }
    });

    return novaLista;
}

export {
    trataMenu
};