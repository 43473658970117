import axios from 'axios';
import jwtDecode from 'jwt-decode';
import store from '@/clarivi/store'

class JwtService {

	auth() {
		this.init();
	}

	init() {

		var domain = window.location.host;
		var domain_api = "";
		switch (domain) {
			//PROD
			case "www.elipseagro.com.br":
			case "elipseagro.com.br":
				domain_api = "https://api.elipseagro.com.br";
				break;

			case "www.elipseagro.com":
			case "elipseagro.com":
				domain_api = "https://api.elipseagro.com";
				break;

			//DEV
			case "app.dev.elipseagro.com.br":
				domain_api = "https://api.dev.elipseagro.com.br";
				break;
			
			case "app.dev.elipseagro.com":
				domain_api = "https://api.dev.elipseagro.com";
				break;

			case "localhost:3000":
				//domain_api = "http://clarivi-analysys.azurewebsites.net";
				domain_api = "https://api.dev.elipseagro.com.br";
				//domain_api = "https://localhost:44305"

				break;

			default:
				domain_api = "https://api.dev.elipseagro.com.br";
				break;
		}

		// axios.defaults.baseURL = 'http://clarivi-analysys.azurewebsites.net';
		// axios.defaults.baseURL = 'https://localhost:44305';
		// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;


		axios.defaults.baseURL = domain_api;
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors() {
		axios.interceptors.response.use((response) => {
			store.commit("SET_DATA", { data: false, id: "loading" });
			return response;
		},
			err => {

				//console.log("interceptor", err.response.data);

				if (err.message === "Network Error") {
					alert('Falha na conexão...');
					store.commit("SET_DATA", { data: false, id: "loading" });
					return new Promise(() => { });
				}


				return new Promise((resolve, reject) => {

					if (err.response.status === 401 && !err.response.data.authenticated) {
						//alert(err.response.data.message);
						//this.emit('onAutoLogout', 'Invalid accessToken');
						//this.setSession(null);

					} else if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						//this.emit('onAutoLogout', 'Invalid accessToken');
						//this.setSession(null);
					}
					store.commit("SET_DATA", { data: false, id: "loading" });
					throw err;
				});
			}
		);

		axios.interceptors.request.use((config) => {
			store.commit("SET_DATA", { data: true, id: "loading" });
			return config;
		}, (error) => {
			return Promise.reject(error);
		});

	};

	getAccessToken() {
		return (window.localStorage.getItem('token') === 'null') ? null : window.localStorage.getItem('token');
	};


	isAuthTokenValid(accessToken) {

		if (accessToken) {

			const decoded = jwtDecode(accessToken);
			const currentTime = Date.now() / 1000;
			if (decoded.exp < currentTime) {

				console.warn('access token expired');
				return false;
			}

			return true;
		} else {

			return false;
		}

	};

	handleAuthentication() {
		const accessToken = this.getAccessToken();

		if (accessToken !== null) {
			if (this.isAuthTokenValid(accessToken)) {


				this.setSession(accessToken);
				//this.emit('onAutoLogin', true);

			} else {

				this.setSession(null);
				//this.emit('onAutoLogout', 'accessToken expired');

			}
		} else {

			//this.emit('onNoAccessToken');
			return;

		}


	};

	setSession(accessToken) {
		if (accessToken) {
			axios.defaults.headers.common.Authorization = `${accessToken}`;
		} else {
			delete axios.defaults.headers.common.Authorization;
		}
	};


	signInWithEmailAndPassword(login, password) {

		return new Promise((resolve, reject) => {
			axios
				.post('/api/login', {
					login,
					password
				},
				)
				.then(response => {


					if (response.data.usuario) {
						this.setSession(response.data.accessToken);

						let usuario = response.data.usuario;

						let user = {
							displayName: usuario.nome,
							photoURL: 'assets/images/avatars/profile.jpg',
							email: login
						}
						resolve(user);
					} else {
						console.log(response);
						reject(response.data.error);
					}
				});
		});
	};

	signInWithToken() {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/access-token', {
					accessToken: this.getAccessToken()
				})
				.then(response => {

					if (response.data.Authenticated) {
						this.setSession(response.data.accessToken);
						resolve(response.data.usuario);

					} else {

						this.logout();
						Promise.reject(new Error('Failed to login with token'));

					}
				})
				.catch(error => {

					//this.logout();

					//Promise.reject(new Error('Failed to login with token.'));

				});
		});
	};

	updateUserData(user) {
		return axios.post('/api/auth/user/update', {
			user
		});
	};






}

const instance = new JwtService();

export default instance;








