export default {
  
  createEvent ({ commit }, event) {
    return commit('CREATE_EVENT', event)
  },
  updateEvent ({ commit }, updatedEvent) {
    return commit('UPDATE_EVENT', updatedEvent)
  },
  deleteEvent ({ commit }, eventId) {
    return commit('DELETE_EVENT', eventId)
  },
  setweekendsVisible ({ commit }, enabled) {
    return commit('SET_WEEKENDS_ENABLED', enabled)
  },

  resetEvents({ commit }, events){
    return commit('RESET_EVENTS', events)
  }

}
