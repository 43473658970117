import axios from 'axios';

const model_name = 'Usuario';
const Model =  {

    login : async (usuario) => {                
        return await axios.post('/api/login', usuario);
    },

    login_empresa : async (empresa) => {                
        return await axios.post('/api/v3/login-empresa', empresa);
    },

    listar: () =>{
        return axios.get('/Usuario');
    },

    salvar: (usuario) =>{
        return axios.post('/Usuario');
    },

    editar: (id, usuario) =>{
        return axios.put('/Usuario');
    },

    deletar: (id) =>{
        return axios.delete('/Usuario');
    },

}

export default Model;