const state = {
  
    token: null,
    loading: false,
    
    menu: [],
    permissoes: [],
    userInfo: {
  
      userId: 0,
      displayName: '',                        
      email: '',
      foto: '',
      
  
    },
  
    sidebarShow: 'responsive',
    sidebarMinimize: false,

    events: [],
    weekendsVisible: true
}
  
export default state;