class MenuItem{
    constructor(user, permissoes, menuCode, name, to, icon = ''){

        this.user = user;
        this.permissoes = permissoes;
        this.menuCode = menuCode;
        this.name = name;
        this.to = to;
        this.icon = icon;

    }

    getMenu(){

        if( this.permissoes.includes(this.menuCode) ){
            return{
                name: this.name ,
                to: this.to,
                icon: this.icon
            }
           
        }
        return null;
    }
}
export default MenuItem;
