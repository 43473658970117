import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import VueRouter from 'vue-router'
/*
import App from './App'
import router from './router'
*/

// importando arquivos do projeto da Clarivi
import App from './clarivi/App'

// Router e Store
import { sync } from 'vuex-router-sync'
import router from './clarivi/router'
import store from '@/clarivi/store'


// Vue Router
Vue.use(VueRouter)
sync(store, router)

// não volta pasta
import './clarivi/assets/css/cssloader.css'
import './clarivi/assets/css/custom.css'


import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'

import { iconsSet as icons } from './assets/icons/icons.js'

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.config.performance = true
Vue.use(CoreuiVuePro)
Vue.prototype.$log = console.log.bind(console)

Vue.prototype.$eventBus = new Vue();


// import plugin
import VueToastr from "vue-toastr";

// use plugin
Vue.use(VueToastr, {
  defaultTimeout: 2000,
  defaultProgressBar: true,
  defaultProgressBarValue: 0,
  defaultCloseOnHover: false,
  defaultClassNames: ["animated", "zoomInUp"]
});

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
