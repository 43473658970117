import MenuItem from './MenuItem';
class MenuDropdown {
    constructor(user, permissoes, menuCode, name, to, icon = '') {

        this.user = user;
        this.permissoes = permissoes;
        this.menuCode = menuCode;
        this.name = name;
        this.to = to;
        this.icon = icon;

        this.menuList = [];

    }

    addMenuItem(menuCode, name, to, icon = '') {

        var oMenuItem = new MenuItem(this.user, this.permissoes, menuCode, name, to, icon);
        var submenu = oMenuItem.getMenu();
        if (submenu) {
            this.menuList.push(submenu);
        }
    }

    addMenuDropdown(oMenuDropdown) {

        var submenu = oMenuDropdown.getMenu();
        if (submenu) {
            this.menuList.push(submenu);
        }

    }

    getMenu() {

        if (this.permissoes.includes(this.menuCode)) {
            return {
                _name: 'CSidebarNavDropdown',
                name: this.name,
                to: this.to,
                icon: this.icon,
                _children: this.menuList,
                itens: this.menuList,
            };
        }
        return null;
    }
}
export default MenuDropdown;
