class MenuManager {
    constructor(user, permissoes) {
        this.user = user;
        this.permissoes = permissoes;
        this.menuList = [];
    }

    addMenuItem(menuCode, name, to, icon = '') {
        if (this.permissoes.includes(menuCode)) {
            this.menuList.push({
                _name: 'CSidebarNavItem',
                name,
                to,
                icon
            })
        }

        return this;
    }

    addMenuDropdown(oMenuDropdown) {

        var submenu = oMenuDropdown.getMenu();
        if (submenu) {
            this.menuList.push(submenu);
        }

        return this;
    }

    getMenu() {

        var menu = [];
        menu.push({
            _name: 'CSidebarNav',
            _children: this.menuList,
            itens: this.menuList,
        })

        return menu;
    }
}

export default MenuManager;


