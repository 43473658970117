import { getEventIndexById } from "./utils";

const mutations = {
  SET_DATA(state, { id, data }) {
    state[id] = data;

    if ((id = "loading" && data == true)) {
      document.getElementById("cssloader").style.display = "block";
    } else if ((id = "loading" && data == false)) {
      document.getElementById("cssloader").style.display = "none";
    }
  },
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  // Eventos do Usuario
  SET_USER(state, userInfo) {
    state.userInfo = userInfo;
    // Save to local storage as well
    if (window.localStorage) {
      window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
  },
  SET_RENOVANDO_TOKEN(state, value) {
    state.renovandoToken = value;
  },
  SET_TOKEN(state, token) {
    state.token = token;
    // Update to local storage as well
    if (window.localStorage) {
      window.localStorage.setItem("token", state.token);
    }
  },

  SET_PERMISSOES(state, value) {
    state.permissoes = value;
    if (window.localStorage) {
      window.localStorage.setItem("menu", state.permissoes);
    }
  },

  // Evento do Menu
  SET_MENU(state, value) {
    state.menu = value;
  },

  // Eventos do Calendario (agenda)
  CREATE_EVENT(state, event) {
    return state.events.push(event);
  },
  UPDATE_EVENT(state, updatedEvent) {
    const index = getEventIndexById(state, updatedEvent.id);

    if (index === -1) {
      return console.warn(`Unable to update event (id ${updatedEvent.id})`);
    }

    return state.events.splice(index, 1, {
      ...state.events[index],
      title: updatedEvent.title,
      start: updatedEvent.start,
      end: updatedEvent.end,
      date: updatedEvent.date,
    });
  },
  DELETE_EVENT(state, eventId) {
    const index = getEventIndexById(state, eventId);

    if (index === -1) {
      return console.warn(`Unable to delete event (id ${eventId})`);
    }

    return state.events.splice(index, 1);
  },
  SET_WEEKENDS_ENABLED(state, enabled) {
    state.weekendsVisible = enabled;
  },
  RESET_EVENTS(state, events) {
    return (state.events = events);
  },
};

export default mutations;
