<template>
  <router-view></router-view>
</template>

<script>

import jwtService from '@/clarivi/api/auth/jwtService';

export default {
  name: 'App',

  beforeCreate(){
    // faz o teste de segurança inicial e ativa os eventos de onNoAccessToken, onAutoLogout, onAutoLogin
    jwtService.auth();

  },

  created(){
    document.getElementById('cssloader').style.display = "none";
  }

}
</script>

<style lang="scss">
  // volta uma pasta
  @import '../assets/scss/style';
  
</style>
