import UsuarioModel from "@/clarivi/api/models/usuarios";
import jwtService from "@/clarivi/api/auth/jwtService";
import store from "@/clarivi/store";

import MenuManager from "@/clarivi/menu";
import { trataMenu } from "@/clarivi/utils/functions";

import axios from 'axios';

const api_esqueci_senha = '/api/esqueci-senha';
const api_nova_senha = '/nova-senha';

export default {
  login: async (usuario) => {
    const response = await UsuarioModel.login(usuario);

    if (response.data.authenticated) {
      let usuario = response.data.user;

      let workspace = response.data.workspace;
      //console.log('workspace', workspace);

      let apiVersion = response.data.apiVersion;
      //console.log('apiVersion', apiVersion);     

      // Verifica se o colaborador logado é do backoffice da clarivi
      let isBackOffice = false;
      if( workspace.empresaId == 1 && workspace.empresa == "Clarivi" && usuario.perfilAtivo == true)
      {
        isBackOffice = true;
      }

      let user = {
        userId: usuario.id,
        displayName: usuario.nome,
        email: usuario.email,

        perfil: usuario.perfil,
        perfilAtivo: usuario.perfilAtivo,
        perfilId: usuario.perfilId,
        redirecionamento: usuario.redirecionamento,

        segmento: workspace.empresaSegmento,
        empresa: workspace.empresa,
        backoffice : isBackOffice,
        empresaId: workspace.empresaId,

        foto:
          !usuario.foto || usuario.foto === null || usuario.foto == ""
            ? "/img/avatars/profile.jpg"
            : usuario.foto,
      };

      let menu = trataMenu(response.data.menu);



      store.commit("SET_USER", user);
      store.commit("SET_TOKEN", response.data.accessToken);
      store.commit("SET_PERMISSOES", menu);
      store.commit("SET_MENU", MenuManager(user, menu));

      jwtService.setSession(response.data.accessToken); // seta token para o axios

      return user;
    } else {
      return response.data.error;
    }
  },

  login_empresa: async (usuario_empresa ) => {
    const response = await UsuarioModel.login_empresa(usuario_empresa);

    if (response.data.authenticated) {
		let usuario = response.data.user;

		let workspace = response.data.workspace;
		//console.log('workspace', workspace);

		let apiVersion = response.data.apiVersion;
		//console.log('apiVersion', apiVersion);     

		// Verifica se o colaborador logado é do backoffice da clarivi
		let isBackOffice = false;
		if( workspace.empresaId == 1 && workspace.empresa == "Clarivi" && usuario.perfilAtivo == true)
		{
			isBackOffice = true;
		}

		let user = {
			userId: usuario.id,
			displayName: usuario.nome,
			email: usuario.email,

			perfil: usuario.perfil,
			perfilAtivo: usuario.perfilAtivo,
			perfilId: usuario.perfilId,
			redirecionamento: usuario.redirecionamento,

			segmento: workspace.empresaSegmento,
			empresa: workspace.empresa,
			backoffice : isBackOffice,
			empresaId: workspace.empresaId,

        foto:
         	 !usuario.foto || usuario.foto === null || usuario.foto == ""
            ? "/img/avatars/profile.jpg"
				: usuario.foto,
		};

		let menu = trataMenu(response.data.menu);

		store.commit("SET_USER", user);
		store.commit("SET_TOKEN", response.data.accessToken);
		store.commit("SET_PERMISSOES", menu);
		store.commit("SET_MENU", MenuManager(user, menu));

		jwtService.setSession(response.data.accessToken); // seta token para o axios

		return user;
    } else {
      	return response.data.error;
    }
  },

  refreshMenu: () => {
    const user = store.state.userInfo;
    const permissoes = store.state.permissoes;
    store.commit("SET_MENU", MenuManager(user, permissoes));
  },

  logout: () => {
    // limpa menu
    store.commit("SET_MENU", null);
    store.commit("SET_USER", null);
    store.commit("SET_PERMISSOES", null);
    store.commit("SET_TOKEN", null);
  },

  enviarEmailRecuperacao: async (email) =>{
    return await axios.post(api_esqueci_senha, email);
  },
  newPassword: async (newPassword) =>{
    // console.log(newPassword)
    // console.log(api_nova_senha + "/" + newPassword.token)
    return await axios.post(`${api_nova_senha}/newPassword.token`, newPassword);
  },
};
